module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1280,"disableBgImageOnAlpha":true}},"gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-autolink-headers","options":{"isIconAfterHeader":true,"icon":"<svg width=\"20\" height=\"20\" viewBox=\"0 0 48 48\" xmlns=\"http://www.w3.org/2000/svg\">\n  <g>\n    <rect width=\"48\" height=\"48\" fill=\"white\" fill-opacity=\"0.01\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" stroke=\"none\" fill-rule=\"evenodd\"/>\n    <g transform=\"translate(6.000000, 5.500000)\">\n      <path d=\"M0,10.5 L36,10.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" stroke=\"currentColor\" fill=\"none\" fill-rule=\"evenodd\"/>\n      <path d=\"M-8,18.5 L28,18.5\" transform=\"translate(10.000000, 18.500000) rotate(90.000000) translate(-10.000000, -18.500000) \" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" stroke=\"currentColor\" fill=\"none\" fill-rule=\"evenodd\"/>\n      <path d=\"M8,18.5 L44,18.5\" transform=\"translate(26.000000, 18.500000) rotate(90.000000) translate(-26.000000, -18.500000) \" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" stroke=\"currentColor\" fill=\"none\" fill-rule=\"evenodd\"/>\n      <path d=\"M0,26.5 L36,26.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" stroke=\"currentColor\" fill=\"none\" fill-rule=\"evenodd\"/>\n    </g>\n  </g>\n</svg>"}},"gatsby-remark-mermaid","@unisite/gatsby-remark-prismjs","gatsby-remark-responsive-iframe"],"rehypePlugins":[null],"remarkPlugins":[null],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/workpath0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@unisite/core-blog/gatsby-browser.js'),
      options: {"plugins":[],"contentName":"@blog","contentPath":"content","tagPrefix":"/tag","tagListPrefix":"/tags","userPrefix":"/user","userListPrefix":"/users","columnPrefix":"/column","columnListPrefix":"/columns","categoryPrefix":"/category","categoryListPrefix":"/categories","postPrefix":"/post","postListPrefix":"/list","archivesPrefix":"/archive"},
    },{
      plugin: require('../node_modules/@unisite/gatsby-plugin-dark-mode/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@unisite/theme-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
